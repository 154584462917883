@import url('https://fonts.googleapis.com/css2?family=Corben&display=swap');
*{
    scroll-behavior: smooth;
}

body{
    font-family: 'Corben', cursive;
}

#Header{
    margin-bottom: 18%;
}

#background-image{
    width: 100%;
    height: 100%;
    position: fixed;
    left: 0px;
    z-index: 0;
}

#logo-image{
    position: relative;
    margin-left: 29%;
    margin-top: 2%;
    height: auto;
    width: 40%;
    z-index: 1;
}

#branding{
    position: relative;
    text-align: center;
    margin-right: 2%;
    margin-top: 5%;
    /* font-weight: bold; */
    font-size: 3rem;
}

#About{
    position: relative;
    z-index: 1;
    height: 100%;
    width: 100%;
    background-color: #06262D;
    opacity: 0.8;
    color: white;
    text-align: center;
    padding-top: 14%;
    padding-bottom: 13%;
}

#About h1{
    font-size: 4rem;
    margin-bottom: 5%;
}

#About h6{
    font-size: 2rem;
}


#AreasOfWork{
    position: relative;
    z-index: 1;
    height: 100%;
    width: 100%;
    background-color: #EF6922;
    opacity: 0.8;
    color: white;
    text-align: center;
    padding-top: 15%;
    padding-bottom: 15%;
}
#AreasOfWork h1{
    color: white;
    font-size: 4rem;
}

#AreasOfWork h3{
    color: white;
    font-size: 2rem;
}

#AreasOfWork div{
    align-content: center;
    margin: 0 auto;
}

#AreasOfWork img{
    border-radius: 50%;
    height: auto;
    width: 15rem;
}

#Commodities{
    position: relative;
    z-index: 1;
    height: 100%;
    width: 100%;
    background-color: #FCE9C1;
    opacity: 0.8;
    color: black;
    text-align: center;
    padding-top: 15%;
    padding-bottom: 15%;
}

#Commodities h1{
    font-size: 4rem;
}

#Commodities h3{
    font-size: 2rem;
}

#Commodities div{
    align-content: center;
    margin: 0 auto;
}

#Commodities img{
    border-radius: 50%;
    height: auto;
    width: 15rem;
}

#Commodities #mineralsImage{
    height: 10rem;
}

#Commodities h3{
    margin-top: 5%;
}

#Commodities #agricultureImage{
    height: 10rem;
}

#Message{
    position: relative;
    background-color: #5E8C61;
    opacity: 0.8;
    text-align: center;
    color: white;
    height: 100%;
    width: 100%;
    padding-top: 18%;
    padding-bottom: 17%;
}

#Message hr{
    border-bottom: 1px solid #707070;
    width: 60%;
}

#Message h1{
    font-size: 4rem;
}

#Message h6{
    font-size: 2rem;
}

#Contact{
    position: relative;
    height: 100%;
    width: 100%;
    background-color: #06262D;
    color: white;
    opacity: 0.8;
    text-align: center;
    padding-top: 15%;
    padding-bottom: 15%;
}

#Footer{
    position: relative;
    height: 100%;
    width: 100%;
    background-color: black;
    opacity: 0.75;
    text-align: center;
    padding-top: 5%;
    padding-bottom: 5%;
    color: white;
}

#Footer h6:last-of-type{
    padding-top: 5%;
    padding-bottom: 0%;
}

