.navbar-toggle {
    margin-top: 5px;
    padding: 5px 5px !important;
}

.nav-logo{
    width: 3%;
    height: auto;
    padding: 0px;
    margin: 0px;
}

.nav-item{
    color: white;
    padding: 0px 10px 0px 10px;
}
.nav-item:last-child{
    border-right: 0px solid white;
}

.landing{
    padding-top: 10%;
    position: relative; 
    z-index: 1;
}

#Navigation{
    margin-bottom: 3%;
    position: relative;
    z-index: 2;
}